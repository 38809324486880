<template>
  <div class="card-content">
    <CCard>
      <CCardHeader>
        <h4 v-if="isEdit">編集</h4>
        <h4 v-else>オーナー登録</h4>
      </CCardHeader>
      <CCardBody>
        <div>
          <CRow>
            <CCol sm="12">
              <label>NO</label>
              <CInput v-model="no" disabled />
            </CCol>
            <CCol sm="12">
              <label>姓</label>
              <CInput
                v-model="infoOwner.lastName"
                v-bind:class="{ 'error-input': infoOwner.lastNameRequired }"
              />
              <div class="message-error" v-if="infoOwner.lastNameRequired">
                {{ feedback.REQUIRED }}
              </div>
            </CCol>
            <CCol sm="12">
              <label>名</label>
              <CInput
                v-model="infoOwner.firstName"
                v-bind:class="{ 'error-input': infoOwner.firstNameRequired }"
              />
              <div class="message-error" v-if="infoOwner.firstNameRequired">
                {{ feedback.REQUIRED }}
              </div>
            </CCol>
            <CCol v-if="isEdit" sm="12">
              <CInput label="オーナーID" v-model="infoOwner.id" disabled />
            </CCol>
            <CCol sm="12">
              <label>メールアドレス</label>
              <CInput
                v-model="infoOwner.email"
                v-bind:class="{
                  'error-input':
                    infoOwner.emailRequired || infoOwner.emailInvalid,
                }"
              />
              <div class="message-error" v-if="infoOwner.emailRequired">
                {{ feedback.REQUIRED }}
              </div>
              <div class="message-error" v-if="infoOwner.emailInvalid">
                {{ feedback.INVALID }}
              </div>
            </CCol>
            <CCol sm="12">
              <label>パスワード</label>
              <CInput
                type="password"
                v-model="infoOwner.password"
                v-bind:class="{
                  'error-input':
                    infoOwner.passwordRequired ||
                    infoOwner.passwordInvalid ||
                    infoOwner.passwordMaxlength,
                }"
              />
              <div class="message-error" v-if="infoOwner.passwordRequired">
                {{ feedback.PASSWORD_NULL }}
              </div>
              <div class="message-error" v-if="infoOwner.passwordMaxlength">
                {{ feedback.PASSWORD_8_CHARACTER }}
              </div>
              <div class="message-error" v-if="infoOwner.passwordInvalid">
                {{ feedback.ALPHANUMERIC_PASSWORD }}
              </div>
            </CCol>
            <CCol sm="12">
              <label>パスワード再入力</label>
              <CInput
                type="password"
                v-model="infoOwner.passwordConfirm"
                v-bind:class="{ 'error-input': infoOwner.passwordNotMatch }"
              />
              <div class="message-error" v-if="infoOwner.passwordNotMatch">
                {{ feedback.PASSWORDINCORRECT }}
              </div>
            </CCol>
            <CCol sm="12">
              <CInput label="自動出力URL" v-model="url" disabled />
            </CCol>
            <CCol sm="12">
              <label>設定ドメイン</label>
              <CInput
                v-model="infoOwner.domain"
                v-bind:class="{ 'error-input': infoOwner.domainInvalid }"
              />
              <div class="message-error" v-if="infoOwner.domainInvalid">
                {{ feedback.INVALID }}
              </div>
            </CCol>
            <CCol sm="12">
              <label>BIレポート用URL</label>
              <CInput
                v-model="infoOwner.urlBI"
                v-bind:class="{ 'error-input': infoOwner.urlBIInvalid }"
              />
              <div class="message-error" v-if="infoOwner.urlBIInvalid">
                {{ feedback.INVALID }}
              </div>
            </CCol>
          </CRow>
          <CRow>
            <CCol sm="12">
              <div class="d-flex justify-content-center">
                <CButton
                  v-on:click.prevent="createOwner()"
                  :color="'success'"
                  :size="'lg'"
                  class="mt-3"
                  :disabled="isLoading"
                >
                  <b-spinner v-if="isLoading" small></b-spinner>
                  <span v-if="isEdit">更新する</span>
                  <span v-else>登録</span>
                </CButton>
                <CButton
                  v-on:click.prevent="goToListOwner()"
                  :color="'secondary'"
                  :size="'lg'"
                  class="mt-3 ml-3"
                >
                  キャンセル
                </CButton>
              </div>
            </CCol>
          </CRow>
        </div>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { Constants } from "../../utils/constants";
import { FeedBack } from "@/utils/feedback.js";

export default {
  name: "CreateOwner",
  data() {
    return {
      feedback: FeedBack,
      no: "",
      infoOwner: {
        lastName: "",
        lastNameRequired: "",
        firstName: "",
        firstNameRequired: false,
        password: "",
        passwordRequired: false,
        passwordInvalid: false,
        passwordMaxlength: false,
        passwordConfirm: "",
        passwordNotMatch: false,
        id: "",
        email: "",
        emailRequired: false,
        emailInvalid: false,
        urlOutput: "https://cdeashop.tk/shop/",
        domain: "",
        domainInvalid: false,
        urlBI: "",
        urlBIInvalid: false,
        idUrl: "",
      },
      url: "",
      isEdit: false,
      isLoading: false,
    };
  },
  created() {
    const id = this.$route.params.id;
    if (id) {
      this.infoOwner.id = id;
      this.isEdit = true;
      this.no = this.$route.query.NO;
      this.getOwnerById(this.infoOwner.id);
    } else {
      this.no = this.$route.query.total;
      const idUrl = this.makeId(9);
      this.infoOwner.urlOutput += idUrl;
      this.url = this.infoOwner.urlOutput;
      this.infoOwner.idUrl = idUrl;
    }
  },
  computed: {
    ...mapGetters(["success", "message", "error", "owner"]),
  },
  watch: {
    owner() {
      this.infoOwner.firstName = this.owner.first_name;
      this.infoOwner.lastName = this.owner.last_name;
      this.infoOwner.email = this.owner.email;
      this.infoOwner.domain = this.owner.domain ? this.owner.domain : "";
      this.infoOwner.urlBI = this.owner.url_bi;
      if (this.owner.url_site) {
        this.url = this.owner.url_site;
      }
    },
    success() {
      if (this.success) {
        this.isLoading = false;
        this.$toasted.success(this.message);
        this.$store.commit("set", ["success", false]);
        this.$store.commit("set", ["message", ""]);
        this.$router.push({
          name: "list owner",
        });
      }
    },
    error() {
      if (this.error) {
        this.isLoading = false;
        this.$toasted.error(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["error", false]);
      }
    },
    "infoOwner.domain"() {
      this.infoOwner.domainInvalid = false;
      if (this.infoOwner.domain) {
        this.url = this.infoOwner.domain;
      } else if (this.owner.domain) {
        this.url = this.owner.domain;
      } else if (this.owner.url_site) {
        this.url = this.owner.url_site;
      } else {
        this.url = this.infoOwner.urlOutput;
      }
    },
    "infoOwner.firstName"() {
      this.infoOwner.firstNameRequired = false;
    },
    "infoOwner.lastName"() {
      this.infoOwner.lastNameRequired = false;
    },
    "infoOwner.password"() {
      this.infoOwner.passwordRequired = false;
      this.infoOwner.passwordInvalid = false;
      this.infoOwner.passwordMaxlength = false;
    },
    "infoOwner.passwordConfirm"() {
      this.infoOwner.passwordNotMatch = false;
    },
    "infoOwner.email"() {
      this.infoOwner.emailRequired = false;
      this.infoOwner.emailInvalid = false;
    },
    "infoOwner.urlBI"() {
      this.infoOwner.urlBIInvalid = false;
    },
  },
  methods: {
    ...mapActions({ createOwnerMaster: "createOwnerMaster" }),
    ...mapActions({ getOwnerById: "getOwnerById" }),
    ...mapActions({ updateOwner: "updateOwner" }),
    createOwner() {
      let error = false;
      if (this.infoOwner.firstName === "") {
        error = true;
        this.infoOwner.firstNameRequired = true;
      }
      if (this.infoOwner.lastName === "") {
        error = true;
        this.infoOwner.lastNameRequired = true;
      }
      if (!this.isEdit && this.infoOwner.password === "") {
        error = true;
        this.infoOwner.passwordRequired = true;
      }
      if (
        !this.isEdit &&
        this.infoOwner.password &&
        this.infoOwner.password.length < 8
      ) {
        error = true;
        this.infoOwner.passwordMaxlength = true;
      }
      if (
        this.infoOwner.password &&
        !this.infoOwner.password.match(Constants.CHECK_PASSWORD)
      ) {
        error = true;
        this.infoOwner.passwordInvalid = true;
      }
      if (
        !this.isEdit &&
        this.infoOwner.password !== this.infoOwner.passwordConfirm
      ) {
        error = true;
        this.infoOwner.passwordNotMatch = true;
      }
      if (this.infoOwner.email === "") {
        error = true;
        this.infoOwner.emailRequired = true;
      }
      if (
        this.infoOwner.email &&
        !this.infoOwner.email.match(Constants.REGEX_EMAIL)
      ) {
        error = true;
        this.infoOwner.emailInvalid = true;
      }
      if (
        this.infoOwner.domain &&
        !this.infoOwner.domain.match(Constants.REGEX_URL)
      ) {
        error = true;
        this.infoOwner.domainInvalid = true;
      }
      if (
        this.infoOwner.urlBI &&
        !this.infoOwner.urlBI.match(Constants.REGEX_URL)
      ) {
        error = true;
        this.infoOwner.urlBIInvalid = true;
      }
      if (!error) {
        this.isLoading = true;
        const {
          id,
          firstName,
          lastName,
          password,
          passwordConfirm,
          email,
          urlOutput,
          domain,
          urlBI,
          idUrl,
        } = this.infoOwner;
        const formCreateOwner = {
          id: id,
          first_name: firstName,
          last_name: lastName,
          password: password ? password : "",
          password_confirm: passwordConfirm ? passwordConfirm : "",
          email: email,
          url_site: this.url ? this.url : urlOutput,
          domain: domain,
          url_bi: urlBI,
          id_url: idUrl,
        };
        if (this.isEdit) {
          this.updateOwner(formCreateOwner);
        } else {
          this.createOwnerMaster(formCreateOwner);
        }
      }
    },
    goToListOwner() {
      this.$router.push({
        name: "list owner",
      });
    },
    makeId(length) {
      var result = "";
      var characters = "0123456789";
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    },
  },
};
</script>
<style lang="scss">
.error-input {
  border: solid 1px #e55353 !important;
}
.message-error {
  color: #e55353;
}
</style>
